import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { SvgPinterest } from 'components/SVG/SvgPinterest';
import { SvgInstagram } from 'components/SVG/SvgInstagram';
import { SvgWhatsApp } from 'components/SVG/SvgWhatsApp';
import { AppLandingHelper } from '@axmit/router-app-landing';
import { EClientRoutes, ELandingRoutes } from 'const/Routes.const';
import { useTranslation } from 'react-i18next';
import { INSTAGRAM_LINK, LES_LANDING_LINK, PINTEREST_LINK, WHATSAPP_LINK } from 'const';
import Layout from 'antd/es/layout';
import { Container } from '@axmit/clp-library/lib/components/index';
import Img from 'gatsby-image';
import { getTranslates } from 'helpers/translate.helper';
import loadable from '@loadable/component';

const MediaLink = loadable(() => import('components/MediaLink'));

const LayoutFooter = () => {
  const data = useStaticQuery(query);

  const {
    i18n: { language }
  } = useTranslation();

  const redirectToClient = (path: string) => {
    AppLandingHelper.toggleRoute(path);
  };

  return (
    <Layout.Footer className="clp-footer">
      <div className="clp-footer__img">
        <Img fixed={data.Footer.childImageSharp.fixed} alt="Montgolfiere" />
      </div>

      <Container>
        <nav className="clp-nav">
          <a href={LES_LANDING_LINK}>{getTranslates(data.allStrapiClpLandCommon, 'footer__les', language)}</a>
          <MediaLink>{getTranslates(data.allStrapiClpLandCommon, 'footer__mkit', language)}</MediaLink>
          <Link to={`/${ELandingRoutes.TOC}`}>{getTranslates(data.allStrapiClpLandCommon, 'footer__tac', language)}</Link>
          <Link to={`/${ELandingRoutes.PrivacyPolicy}`}>
            {getTranslates(data.allStrapiClpLandCommon, 'footer__ppolicy', language)}
          </Link>
          <a onClick={() => redirectToClient(EClientRoutes.ContactUs)}>
            {getTranslates(data.allStrapiClpLandCommon, 'footer__contact', language)}
          </a>
        </nav>

        <nav className="clp-footer__social">
          <a key="pinterest" href={PINTEREST_LINK} target="_blank" rel="noopener noreferrer">
            <SvgPinterest />
          </a>

          <a key="Instagram" href={INSTAGRAM_LINK} target="_blank" rel="noopener noreferrer">
            <SvgInstagram />
          </a>

          <a key="Whatsapp" href={WHATSAPP_LINK} target="_blank" rel="noopener noreferrer">
            <SvgWhatsApp />
          </a>
        </nav>

        <span className="clp-footer__info">&copy;2020 by Clé Privée</span>
      </Container>
    </Layout.Footer>
  );
};
export default LayoutFooter;

export const query = graphql`
  query {
    Footer: file(relativePath: { eq: "images/footerMontgolfiere.png" }) {
      childImageSharp {
        fixed(width: 320, quality: 100, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    allStrapiClpLandCommon {
      edges {
        node {
          footer__les__en
          footer__les__fr
          footer__mkit__en
          footer__mkit__fr
          footer__tac__en
          footer__tac__fr
          footer__ppolicy__en
          footer__ppolicy__fr
          footer__contact__en
          footer__contact__fr
        }
      }
    }
  }
`;
