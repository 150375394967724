import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getTranslates } from 'helpers/translate.helper';
import { useTranslation } from 'react-i18next';

interface IComponentProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  logo?: string;
}

export const LogoGift = ({ className = '', logo, ...params }: IComponentProps) => {
  const data = useStaticQuery(query);
  const {
    i18n: { language }
  } = useTranslation();
  const description = getTranslates(data.allStrapiClpLandCommon, 'logo__desc', language) || '';

  return (
    <div className={`clp-logo ${params.onClick ? 'c-pointer' : ''} ${className}`} {...params}>
      <div>
        <img src={logo} alt="clé privée" />
      </div>

      <div className="clp-logo__text-wrapper">
        <div className="clp-logo__label">clé privée</div>
        {description && <small className="clp-logo__sub-label">{description}</small>}
      </div>
    </div>
  );
};

export const query = graphql`
  query {
    allStrapiClpLandCommon {
      edges {
        node {
          logo__desc__en
          logo__desc__fr
        }
      }
    }
  }
`;
