import React from 'react';
import { HorizontalMenu } from '@axmit/clp-library';
import Menu from 'antd/lib/menu';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useCookies } from 'react-cookie';
import { AppLandingHelper } from '@axmit/router-app-landing';
import { Logo } from 'components/Logo';
import julesMobile from 'static/images/julesMobile.png';
import { EClientRoutes, ELandingRoutes, COOKIES_NAME, COOKIES_ROLE, EUserRoles } from 'const';
import { languages } from 'locales/i18n';
import { Header } from 'components/Common/Header';
import { TabsHooks } from 'hooks/Tabs.hooks';
import { getTranslates } from 'helpers/translate.helper';
import { useTranslation } from 'react-i18next';
import i18n from 'locales/i18n';

export const LayoutHeader = () => {
  const {
    i18n: { language }
  } = useTranslation();
  const data = useStaticQuery(query);
  const [cookies] = useCookies([COOKIES_NAME, COOKIES_ROLE]);
  const { activeTabs } = TabsHooks.useSelectedTabs(availableRoutes, '');

  const isAuthorized = !!cookies[COOKIES_NAME];
  const isBackOfficeUser =
    isAuthorized && (cookies[COOKIES_ROLE] === EUserRoles.Administrator || cookies[COOKIES_ROLE] === EUserRoles.Manager);

  return (
    <Header
      leftSlot={<Logo />}
      rightSlot={
        <HorizontalMenu
          selectedKeys={activeTabs}
          mobileImg={julesMobile}
          currentLanguage={i18n.language}
          languages={languages}
          updateLanguage={lng => {
            i18n.changeLanguage(lng);
          }}
        >
          <Menu.Item key={ELandingRoutes.Philosophy} onClick={() => navigate(`${ELandingRoutes.Philosophy}`)}>
            {getTranslates(data.allStrapiClpLandCommon, 'header__philosophy', language)}
          </Menu.Item>

          <Menu.Item key={ELandingRoutes.How} onClick={() => navigate(`${ELandingRoutes.How}`)}>
            {getTranslates(data.allStrapiClpLandCommon, 'header__how', language)}
          </Menu.Item>

          <Menu.Item key={ELandingRoutes.FAQ} onClick={() => navigate(`${ELandingRoutes.FAQ}`)}>
            {getTranslates(data.allStrapiClpLandCommon, 'header__faq', language)}
          </Menu.Item>

          <Menu.Item
            key="discover"
            disabled={isBackOfficeUser}
            onClick={() => {
              AppLandingHelper.toggleRoute(EClientRoutes.Discover);
            }}
          >
            {getTranslates(data.allStrapiClpLandCommon, 'header__gift', language)}
          </Menu.Item>

          <Menu.Item key="desktopLogin" onClick={() => AppLandingHelper.toggleRoute(EClientRoutes.LogIn)} className="hidden_mob">
            {cookies[COOKIES_NAME] || getTranslates(data.allStrapiClpLandCommon, 'header__login', language)}
          </Menu.Item>

          <Menu.Item
            key="mobileLogin"
            onClick={() => AppLandingHelper.toggleRoute(isAuthorized ? EClientRoutes.Logout : EClientRoutes.LogIn)}
            className="hidden_desk mt-20"
          >
            {isAuthorized
              ? getTranslates(data.allStrapiClpLandCommon, 'header__logout', language)
              : getTranslates(data.allStrapiClpLandCommon, 'header__login', language)}
          </Menu.Item>
        </HorizontalMenu>
      }
    />
  );
};

const availableRoutes: string[] = [ELandingRoutes.How, ELandingRoutes.Philosophy, ELandingRoutes.FAQ];

export const query = graphql`
  query {
    allStrapiClpLandCommon {
      edges {
        node {
          header__login__en
          header__login__fr
          header__logout__en
          header__logout__fr
          header__philosophy__en
          header__philosophy__fr
          header__gift__en
          header__gift__fr
          header__how__en
          header__how__fr
          header__faq__en
          header__faq__fr
          header__mob__gifts__en
          header__mob__gifts__fr
          header__mob__personal__en
          header__mob__personal__fr
          header__mob__banking__en
          header__mob__banking__fr
        }
      }
    }
  }
`;
