import React, { PropsWithChildren } from 'react';
import { MainLayout } from '@axmit/clp-library';
import { LayoutHeader } from 'components/LayoutHeader';
import LayoutFooter from 'components/LayoutFooter';
import ContactUsPenguin from 'components/ContactUsPenguin';
// i18n initialization
import 'locales/i18n';
import { withTranslation } from 'react-i18next';
import * as Sentry from '@sentry/gatsby';

type AllProps = PropsWithChildren<{}>;

export const LayoutComponent = ({ children }: AllProps) => {
  React.useEffect(() => {
    // TODO: Create env variable for node_env
    const isProd =
      window?.origin.startsWith('https://cleprivee.com') ||
      window?.origin.startsWith('https://lesembellisseurs.com/') ||
      window?.origin.startsWith('https://stg.');
    Sentry.setTag('env', isProd ? 'production' : 'development');

    // @ts-ignore
    window.checkSentry = (text?: string) => {
      const msg = `Sentry successfully connected. Config: ${JSON.stringify(process.env) ||
        'Empty'} ${new Date()}. Test string: ${text}`;
      console.log('Go to slack channel and check an error with message: ');
      console.log(msg);
      console.log('SENTRY_DSN', process.env.SENTRY_DSN);
      console.log('REACT_APP_SENTRY_DSN', process.env.REACT_APP_SENTRY_DSN);
      // @ts-ignore
      console.log('window.sentry_dsn', window.sentry_dsn);
      Sentry.captureException(msg);
    };
  }, []);

  return (
    <>
      <LayoutHeader />

      <MainLayout>{children}</MainLayout>

      <ContactUsPenguin />

      <LayoutFooter />
    </>
  );
};

export const Layout = withTranslation()(LayoutComponent);
