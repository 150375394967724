export enum ELandingRoutes {
  How = 'how',
  FAQ = 'faq',
  TOC = 'toc',
  PrivacyPolicy = 'privacy-policy',
  Philosophy = 'philosophy'
}

export enum EClientRoutes {
  BankingMobile = 'banking-view',
  PersonalMobile = 'personal-view',
  Logout = 'logout',
  Discover = 'discover',
  LogIn = 'login',
  NewGift = 'new-gift',
  ContactUs = 'contact-us'
}
