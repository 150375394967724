import React from 'react';
import { Link } from 'gatsby';

import logo from 'static/images/logo.png';
import { LogoGift } from 'components/Common/LogoGift';

export const Logo = () => {
  return (
    <Link to="/" className="logo">
      <LogoGift logo={logo} />
    </Link>
  );
};
