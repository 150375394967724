import React from 'react';
import { EClientRoutes } from 'const';
import { AppLandingHelper } from '@axmit/router-app-landing';
import { isTablet, isIOS, isMobile as rddIsMobile } from 'react-device-detect';
import { graphql, useStaticQuery } from 'gatsby';
import { getTranslates } from 'helpers/translate.helper';
import { useTranslation } from 'react-i18next';

const ContactUsPenguin = () => {
  const data = useStaticQuery(query);
  const {
    i18n: { language }
  } = useTranslation();

  // need for gatsby correct working
  const isMobile = typeof navigator === 'undefined' || rddIsMobile;
  const isIPad = isIOS && isTablet;

  if (isMobile && !isIPad) {
    return null;
  }

  const textBlockClasses = `contact-us-penguin__text-block ${isIPad ? 'contact-us-penguin__text-block__ipad' : ''}`;

  return (
    <div className="contact-us-penguin" onClick={() => AppLandingHelper.toggleRoute(EClientRoutes.ContactUs)}>
      <div className="contact-us-penguin__img" />
      <div className={textBlockClasses}>{getTranslates(data.allStrapiClpLandCommon, 'sticky__penguin', language)}</div>
    </div>
  );
};

export default ContactUsPenguin;

export const query = graphql`
  query {
    allStrapiClpLandCommon {
      edges {
        node {
          sticky__penguin__en
          sticky__penguin__fr
        }
      }
    }
  }
`;
